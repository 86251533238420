export const CONTENT_TEMPLATE_COLUMN_DEVKEYS = [
  'template_cx1',
  'template_cx11',
  'template_cx12',
  'template_cx21',
  'template_cx13',
  'template_cx31',
  'template_cx111',
  'template_cx112',
  'template_cx121',
  'template_cx211',
  'template_cx1111',
]

export const CONTENT_COMPONENTS = {
  RICH_TEXT: 'RichText',
  IMAGE_MAP: 'ImageMap',
  TILES: 'Tiles',
  HERO: 'Hero',
  MENU: 'Menu',
  REFERENCE: 'Reference',
  CAROUSEL: 'Carousel',
  ACCORDION: 'Accordion',
  CUSTOM_COMPONENT: 'CustomComponent',
}

export const DIGITAL_LIBRARY_FILTER_PARAMS = {
  'videos-webinars-reasearch-and-disease-area': 'research',
  'videos-webinars-application-and-technology': 'application',
  'videos-webinars-category': 'category',
  'videos-webinars-language': 'lang',
  'downloadable-literature-category-applications': 'application',
  'downloadable-literature-category-research-areas': 'research',
  'downloadable-literature-category-reproval': 'category',
  'downloadable-literature-category-environmental-and-social-responsibility': 'environmental-social-responsibility',
  'cancer-biomarker-guide-biomarker-type': 'biomarker',
  'cancer-biomarker-guide-organ-tissue-type': 'organ-tissue',
} as const
